@use "styles/keyframes/slideInDown";

.slide-down {
    animation-duration: 0.5s;
    animation-fill-mode: both;
    animation-name: slideInDown;
}

.slide-up {
    animation-duration: 0.1s;
    animation-fill-mode: both;
    animation-direction: reverse;
    animation-name: slideInDown;
}

.out-portal-parent {
    & > div {
        width: 100%;
    }
}
