/* config */
:root {
    --sk-size: 40px;
    --sk-color: #333;
}

/* utility class for centering */
.sk-center {
    margin: auto;
}

/*  fold
    <div class="sk-fold">
        <div class="sk-fold-cube"></div>
        <div class="sk-fold-cube"></div>
        <div class="sk-fold-cube"></div>
        <div class="sk-fold-cube"></div>
    </div>
*/
.sk-fold {
    width: var(--sk-size);
    height: var(--sk-size);
    position: relative;
    transform: rotateZ(45deg);
}

.sk-fold-cube {
    float: left;
    width: 50%;
    height: 50%;
    position: relative;
    transform: scale(1.1);
}

.sk-fold-cube:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--sk-color);
    animation: sk-fold 2.4s infinite linear both;
    transform-origin: 100% 100%;
}

.sk-fold-cube:nth-child(2) {
    transform: scale(1.1) rotateZ(90deg);
}

.sk-fold-cube:nth-child(4) {
    transform: scale(1.1) rotateZ(180deg);
}

.sk-fold-cube:nth-child(3) {
    transform: scale(1.1) rotateZ(270deg);
}

.sk-fold-cube:nth-child(2):before {
    animation-delay: 0.3s;
}

.sk-fold-cube:nth-child(4):before {
    animation-delay: 0.6s;
}

.sk-fold-cube:nth-child(3):before {
    animation-delay: 0.9s;
}

@keyframes sk-fold {
    0%,
    10% {
        transform: perspective(140px) rotateX(-180deg);
        opacity: 0;
    }
    25%,
    75% {
        transform: perspective(140px) rotateX(0deg);
        opacity: 1;
    }
    90%,
    100% {
        transform: perspective(140px) rotateY(180deg);
        opacity: 0;
    }
}
