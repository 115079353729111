@use "styles/vendors";

.starting {
    p {
        text-align: center;
        margin-bottom: 100px;
    }

    :global(.sk-fold) {
        margin-top: 100px;
    }
}

.start-error {
    text-align: center;

    margin-top: 50px;
    margin-bottom: 100px;

    p {
        color: vendors.$danger;
        text-align: center;
    }
}
