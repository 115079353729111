@use "styles/vendors";

.doc-icon {
    padding-left: 0 !important;
    padding-right: 0 !important;

    img {
        width: 24px;
    }
}

.doc-name {
    padding-left: 0 !important;
}
