.single-cell {
    width: 15%;
}

.button-cell {
    width: 10%;
}

.comments-cell {
    width: 20%;
    word-break: break-all;
}
