@keyframes flash {
    from,
    to {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }
}

.recording-indicator {
    animation: flash ease 5s infinite;
}
