@use "vendors";

// ----------------------------------------overwrite bootstrap----------------------------------------

caption {
    // just go with py-2
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

dd,
dl,
dt,
label,
p,
table,
ul {
    margin-bottom: 0px !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    // margin-top: 8px !important; // have mt-2, if something is above
    margin-bottom: 4px !important; // just go with mb-1
}

.alert {
    // just go with mb-2
    margin-bottom: 8px !important;
}

hr {
    // just go with my-2
    margin-top: 8px !important;
    margin-bottom: 8px !important;
}

.is-invalid,
.is-invalid-warning {
    &.is-invalid {
        border-color: vendors.$danger !important;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23FF4136' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23FF4136' stroke='none'/%3e%3c/svg%3e") !important;
    }

    &.is-invalid-warning {
        border-color: vendors.$warning !important;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23FF851B' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23FF851B' stroke='none'/%3e%3c/svg%3e") !important;
    }

    background-repeat: no-repeat !important;
    background-position-x: right !important;
    background-position-y: center !important;
    background-size: vendors.$valid-image-size vendors.$valid-image-size !important;
    padding-right: calc(
        4px + #{vendors.$valid-image-size}
    ) !important; // some pr-1 for distance of number and image
}

// fixing wrong position of validation icon after build. only for text/number-inputs
.was-validated .form-control:invalid,
.form-control.is-invalid,
.form-control.is-invalid-warning,
.was-validated .form-control:valid,
.form-control.is-valid {
    background-position: right vendors.$valid-image-size-half center !important;
    padding-right: calc(
        4px + #{vendors.$valid-image-size} + #{vendors.$valid-image-size-half}
    ) !important; // first 2 just like top paddings, last is extra padding for button distance

    &.inline-select-dropdown {
        // we add 12px for dropdown-arrow in both below: sqrt(8.5^2+8.5^2)
        background-position: right
            calc(#{vendors.$valid-image-size-half} + 12px) center !important;
        padding-right: calc(
            4px + #{vendors.$valid-image-size} + #{vendors.$valid-image-size-half} +
                12px
        ) !important;
    }
}

// validation of form for checkbox
.was-validated input[type="checkbox"]:invalid {
    &::before {
        content: "" !important;
        display: inline-block !important;
        width: 13px !important;
        height: 13px !important;
        border: 2px solid vendors.$danger-border !important;
        border-radius: 0.25rem !important;
        vertical-align: top !important; // top of the checkbox
    }
}

// adding colored border to navbar
.navbar {
    padding: 4px !important;
    border-width: 0 0 4px 0 !important;
    border-color: vendors.$primary !important;
    // height: 50px !important; // looks bad, if screen is small
}

// remove odd padding
.navbar-brand {
    padding: 0px !important;
}

// changing primary button colors on hover
// adding primary color scheme to bisabled buttons
.btn-primary {
    &:disabled {
        border-color: vendors.$primary-border !important;
    }

    &:hover {
        background-color: vendors.$primary-hover !important;
        border-color: vendors.$primary-border-hover !important;
    }

    &:focus,
    &.focus,
    &:active,
    &.active:focus {
        outline: none !important;
        box-shadow: vendors.$primary-box-shadow !important;
    }
}

// bootswatch for some reason does not provide this
.btn-secondary {
    &:disabled {
        border-color: vendors.$secondary-border !important;
    }

    &:hover {
        border-color: vendors.$secondary-border-hover !important;
    }

    &:focus,
    &.focus,
    &:active,
    &.active:focus {
        outline: none !important;
        box-shadow: vendors.$secondary-box-shadow !important;
    }
}

// bootswatch for some reason does not provide this
.btn-info {
    &:disabled {
        border-color: vendors.$info-border !important;
    }

    &:hover {
        border-color: vendors.$info-border-hover !important;
    }

    &:focus,
    &.focus,
    &:active,
    &.active:focus {
        outline: none !important;
        box-shadow: vendors.$info-box-shadow !important;
    }
}

// just border darker
.btn-warning {
    &:not(:hover) {
        border-color: vendors.$warning-border !important;
    }

    &:hover {
        border-color: vendors.$warning-border-hover !important;
    }

    &:focus,
    &.focus,
    &:active,
    &.active:focus {
        outline: none !important;
        box-shadow: vendors.$warning-box-shadow !important;
    }
}

// just border darker
.btn-danger {
    &:not(:hover) {
        border-color: vendors.$danger-border !important;
    }

    &:hover {
        border-color: vendors.$danger-border-hover !important;
    }

    &:focus,
    &.focus,
    &:active,
    &.active:focus {
        outline: none !important;
        box-shadow: vendors.$danger-box-shadow !important;
    }
}

.btn-link {
    text-transform: none !important;
}

// no "!important", pb-0 would not work
.alert,
.card-header,
.modal-header,
.toast-header,
.card-body,
.modal-body,
.toast-body,
.card-footer,
.modal-footer,
.toast-footer,
.navbar,
.list-group-item {
    padding: vendors.$card-spacer-y;
}

// only left&right margin
.container-fluid {
    padding-left: vendors.$card-spacer-y !important;
    padding-right: vendors.$card-spacer-y !important;
}

.modal-footer {
    > * {
        margin: 0px; // no "!important", as it will overwrite bootstrap
    }
}

// uses white text on card-headers
.card-header.bg-primary,
.card-header.bg-success,
.card-header.bg-danger,
.card-header.bg-warning,
.card-header.bg-info,
.card-header.bg-primary > a:not(.btn) {
    color: vendors.$white !important;
}

// overwriting bg primary
.bg-primary {
    background-color: vendors.$primary !important;
    border-color: vendors.$primary-border !important;
}

// nav-links ugly bottom border stuff
.nav-link {
    border-bottom-color: transparent !important;

    > span.nav-span {
        display: block !important;
        height: 22px !important;
    }
}

// overwriting table defaults
.table .thead-dark th {
    background-color: vendors.$primary !important;
    border-color: vendors.$primary !important;
    color: vendors.$white !important;

    // make table header links (used for sorting) visible when using dark table headers
    a {
        color: vendors.$white !important;
    }
}

// overwrite to match our navbar perfectly
.sticky-top {
    top: 46px !important;
    position: sticky !important;
    z-index: 999 !important; // overwrite set z-index
}

.fixed-bottom {
    z-index: auto !important; // overwrite set z-index
}

// ----------------------------------------custom styles----------------------------------------

// just a primary-color class
.primary-color {
    background-color: vendors.$primary !important;
}

// just a primary-color text
.primary-color-text {
    color: vendors.$primary !important;
}

.pointer-cursor {
    cursor: pointer !important;
}

.grab-cursor {
    cursor: grab !important;
}

.can-go-fullscreen {
    &:not(:fullscreen) {
        #fullscreen-only {
            display: none !important;
        }
    }

    &:fullscreen {
        #fullscreen-never {
            display: none !important;
        }
    }
}

// overwriting checkbox defaults
.checkbox-primary {
    accent-color: vendors.$primary !important;
}

// overwriting badge defaults
.badge-primary {
    background-color: vendors.$primary !important;
}

// tab-height
.tab {
    height: 44px !important;
}

.bg-focus {
    background-color: beige !important;
}

.bg-tertiary {
    background-color: vendors.$secondary-hover !important;
    border-color: vendors.$secondary-border-hover !important;
}

// bootstrapp-4 moves table caption to bottom, we want it at the top
caption {
    caption-side: top !important;
}

// make axis title bold to avoid blurry text
svg .axis > text.title {
    font-weight: bolder !important;
}

.space-nowrap {
    white-space: nowrap !important;
}

.text-dot-overflow {
    overflow: hidden !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
}

.div-overflow-x {
    overflow-x: auto !important;
    overflow-y: hidden !important;
}

.div-overflow-y {
    overflow-x: hidden !important;
    overflow-y: auto !important;
}

// add has-button class to align buttons
.has-buttons {
    display: flex !important;
    padding-right: vendors.$card-spacer-y !important;

    & > *:first-child {
        flex-grow: 1 !important;
        align-self: center !important;

        // just like text-dot-overflow in customization.ts
        overflow: hidden !important;
        white-space: nowrap !important;
        text-overflow: ellipsis !important;
    }

    & > *:not(:first-child) {
        @media print {
            display: none !important;
        }
    }

    .btn,
    .btn-group {
        white-space: nowrap !important;
    }

    .card-height {
        padding-top: 27px; // 8px padding, 4px border, 15px actual button
    }
}

// expands the link to take full space of the card footer to make it easier to click
.card-footer.single-href {
    padding: 0 !important;
    height: auto !important;

    a {
        width: 100% !important;
        display: block !important;
        padding: vendors.$card-spacer-y !important;
    }
}

.inline-string-edit {
    max-width: calc(100% - 22px) !important;
}

// center align columns except the first column
table.table-center {
    th:not(:first-child),
    td:not(:first-child) {
        text-align: center !important;
    }
}

// right align columns except the first column
table.table-right {
    th:not(:first-child),
    td:not(:first-child) {
        text-align: right !important;
    }
}

// center align columns except the first 2 columns
table.table-right-2 {
    th:nth-child(n + 3),
    td:nth-child(n + 3) {
        text-align: right !important;
    }
}

// center align columns, just like "table-right" but no "!important"
table.table-right-sticky {
    th:not(:first-child),
    td:not(:first-child) {
        text-align: right; // do not add "!important" here !!!!!!!!!!!
    }
}

// increase the clickable area for the checkbox
.table-checkbox-padding {
    padding: 0 !important;

    label {
        margin-bottom: 0 !important;
        vertical-align: -webkit-baseline-middle !important; // middle of the text

        input {
            margin: vendors.$table-cell-padding-sm !important; // checkbox cannot have padding for some reason
        }
    }

    // overwrite label position in table header
    label#th-checkbox {
        vertical-align: text-bottom !important; // middle of the text
    }
}

// manual stripping of rows when we use multiple rows for the same object
tr.striped {
    background-color: vendors.$table-accent-bg !important;
}

.info-dl {
    display: flex !important;
    flex-wrap: wrap !important;
    margin-bottom: 0px !important;

    dt {
        text-align: right !important;
        max-width: 40% !important;
        flex-basis: 40% !important;
        padding-left: map-get(vendors.$spacers, 2) !important;
        padding-right: map-get(vendors.$spacers, 2) !important;
    }

    dd {
        max-width: 60% !important;
        flex-basis: 60% !important;
        padding-left: map-get(vendors.$spacers, 2) !important;
        padding-right: map-get(vendors.$spacers, 2) !important;
    }
}

// style simple number inputs
.simple-number {
    width: 100% !important;
    text-align: center !important;
    border: 0 !important;
    border-bottom: vendors.$border-width solid vendors.$border-color !important;
    background-color: transparent !important;

    // to mimik form-control focus-highlight
    &:focus {
        border-radius: 0.25rem !important;
        box-shadow: 0 0 0 0.2rem vendors.$primary-box-shadow !important;
        outline: none !important;
    }

    &.is-invalid {
        color: vendors.$danger !important;
        border-bottom-color: vendors.$danger !important;
    }
}

// style for all the cycles in svg
.element-transparent {
    opacity: 0.2 !important;

    &:hover {
        opacity: 1 !important;
    }
}

// style for canvas images
.canvas-chart {
    position: absolute !important;

    width: 100% !important;
    height: 100% !important;
}

.chart-invisible {
    position: absolute !important; // needed for svg-only charts to not collapse
    visibility: hidden !important;
    margin: -100000px !important;
}
