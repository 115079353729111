@import "../variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/functions";

$selectize-font-family: inherit !default;
$selectize-font-size: inherit !default;
$selectize-line-height: $input-btn-line-height !default; // formerly line-height-computed

$selectize-color-text: gray("800") !default; // $gray-800
$selectize-color-highlight: rgba(255, 237, 40, 0.4) !default;
$selectize-color-input: $input-bg !default;
$selectize-color-input-full: $input-bg !default;
$selectize-color-input-error: theme-color("danger") !default;
$selectize-color-input-error-focus: darken(
    $selectize-color-input-error,
    10%
) !default;
$selectize-color-disabled: $input-bg !default;
$selectize-color-item: #efefef !default;
$selectize-color-item-border: #999 !default;
$selectize-color-item-active: $component-active-bg !default;
$selectize-color-item-active-text: #fff !default;
$selectize-color-item-active-border: rgba(0, 0, 0, 0) !default;
$selectize-color-optgroup: $dropdown-bg !default;
$selectize-color-optgroup-text: $dropdown-header-color !default;
$selectize-color-optgroup-border: $dropdown-divider-bg !default;
$selectize-color-dropdown: $dropdown-bg !default;
$selectize-color-dropdown-border-top: mix(
    $input-border-color,
    $input-bg,
    0.8
) !default;
$selectize-color-dropdown-item-active: $dropdown-link-hover-bg !default;
$selectize-color-dropdown-item-active-text: $dropdown-link-hover-color !default;
$selectize-color-dropdown-item-create-active-text: $dropdown-link-hover-color !default;
$selectize-opacity-disabled: 0.5 !default;
$selectize-shadow-input: none !default;
$selectize-shadow-input-focus: inset 0 1px 2px rgba(0, 0, 0, 0.15) !default;
$selectize-shadow-input-error: inset 0 1px 1px rgba(0, 0, 0, 0.075) !default;
$selectize-shadow-input-error-focus: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 6px lighten($selectize-color-input-error, 20%) !default;
$selectize-border: 1px solid $input-border-color !default;
$selectize-border-radius: $input-border-radius !default;

$selectize-width-item-border: 0 !default;
$selectize-padding-x: $input-btn-padding-x !default;
$selectize-padding-y: $input-btn-padding-y !default;
$selectize-padding-dropdown-item-x: $input-btn-padding-x !default;
$selectize-padding-dropdown-item-y: 3px !default;
$selectize-padding-item-x: 3px !default;
$selectize-padding-item-y: 1px !default;
$selectize-margin-item-x: 3px !default;
$selectize-margin-item-y: 3px !default;
$selectize-caret-margin: 0 !default;

$selectize-arrow-size: 5px !default;
$selectize-arrow-color: $selectize-color-text !default;
$selectize-arrow-offset: calc(#{$selectize-padding-x} + 5px) !default;

@import "./selectize";
// @import "./plugins/drag_drop";
// @import "./plugins/dropdown_header";
// @import "./plugins/optgroup_columns";
// @import "./plugins/remove_button";

.selectize-dropdown,
.selectize-dropdown.form-control {
    height: auto;
    padding: 0;
    margin: 2px 0 0 0;
    z-index: $zindex-dropdown;
    background: $selectize-color-dropdown;
    border: 1px solid $dropdown-border-color; // $dropdown-fallback-border
    border: 1px solid $dropdown-border-color; // $dropdown-border;
    @include selectize-border-radius($border-radius);
    @include selectize-box-shadow(0 6px 12px rgba(0, 0, 0, 0.175));
}

.selectize-dropdown {
    .optgroup-header {
        font-size: $font-size-sm;
        line-height: $line-height-base;
    }
    .optgroup:first-child:before {
        display: none;
    }
    .optgroup:before {
        content: " ";
        display: block;
        // @include nav-divider();
        margin-left: $selectize-padding-dropdown-item-x * -1;
        margin-right: $selectize-padding-dropdown-item-x * -1;
    }

    .create {
        padding-left: $selectize-padding-dropdown-item-x;
    }
}

.selectize-dropdown-content {
    padding: 5px 0;
}

.selectize-dropdown-header {
    padding: $selectize-padding-dropdown-item-y * 2
        $selectize-padding-dropdown-item-x;
}

.selectize-input {
    min-height: $input-height;

    &.dropdown-active {
        @include selectize-border-radius($selectize-border-radius);
    }
    &.dropdown-active::before {
        display: none;
    }
    &.focus {
        $color: $gray-500;
        // $color-rgba: rgba(red($color), green($color), blue($color), .6);
        border-color: $color;
        outline: 0;
        @include selectize-box-shadow(none)
            // @include selectize-box-shadow(#{"inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px ${color-rgba}"});;;
;
    }
}

.is-invalid .selectize-input {
    border-color: $selectize-color-input-error;
    @include selectize-box-shadow($selectize-shadow-input-error);

    &:focus {
        border-color: $selectize-color-input-error-focus;
        // @include selectize-box-shadow(none)
        @include selectize-box-shadow($selectize-shadow-input-error-focus);
    }
}

.selectize-control {
    &.multi {
        .selectize-input.has-items {
            padding-left: calc(
                #{$selectize-padding-x} - #{$selectize-padding-item-x}
            );
            padding-right: calc(
                #{$selectize-padding-x} - #{$selectize-padding-item-x}
            );
        }
        .selectize-input > div {
            @include selectize-border-radius(
                calc(#{$selectize-border-radius} - 1px)
            );
        }
    }
}

.form-control.selectize-control {
    padding: 0;
    height: auto;
    border: none;
    background: none;
    @include selectize-box-shadow(none);
    @include selectize-border-radius(0);
}
