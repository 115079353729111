@use "styles/vendors";

.color-option {
    color: lightgray;
}

.scrollable {
    max-height: 400px;
    width: 100%;
    min-width: auto !important; // overwrite bootstrap
}

.placeholder-input::placeholder {
    color: vendors.$input-color !important;
}

.mimic-select::after {
    content: "";
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    width: 6px;
    height: 6px;
    border-right: 2px solid #555;
    border-bottom: 2px solid #555;
}
