@use "styles/vendors";

.top-right {
    position: fixed;
    width: 100%; // .toast already limit the maximum width
    right: map-get(vendors.$spacers, 3);
    top: map-get(vendors.$spacers, 3) + 3.5rem;
    z-index: 1020;

    > div {
        margin-bottom: 0px !important;
    }
}
